import * as React from 'react';

import { Text, BlogImage, BlogLink, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import uninterestedChild from '../../../images/educational-corner/blog23/Nezainteresirano-dijete.jpg';
import connectingWithYourChild from '../../../images/educational-corner/blog23/Povezivanje-s-djetetom.jpg';
import familyStudy from '../../../images/educational-corner/blog23/Ucenje-uz-cijelu-obitelj.jpg';
import kokolingoShop from '../../../images/educational-corner/blog21/glas-Š-trgovina.png';
import kokolingoStories from '../../../images/educational-corner/blog21/glas-Š-price.png';

const SpeechTherapistsAndKokolingo = () => {
  return (
    <PageNew
      title="KOKOLINGO - Kako motivirati djecu da ustraju u logopedskim vježbama?"
      description="Mnogi se roditelji pitaju kako svoje dijete motivirati 
      za učenje, usvajanje novih znanja i vještina te kako postići da 
      dijete ne odustane tijekom vremena ili prilikom prvih pogrešaka 
      koje su u pravilu - neminovne. U programu Kokolingo u tome, srećom, 
      imate pomoć dodatnog animatora, a to je papigica Koko. Papigici Koko 
      život su udahnuli grafički dizajneri i programeri, ali stručnim savjetima 
      kreirali su je i logopedi."
      headline="Kako motivirati djecu da ustraju u logopedskim vježbama?"
    >
      <Text as="p">
        Mnogi se roditelji pitaju kako svoje dijete motivirati za učenje,
        usvajanje novih znanja i vještina te kako postići da dijete ne odustane
        tijekom vremena ili prilikom prvih pogrešaka koje su u pravilu -
        neminovne.
      </Text>
      <BlogImage
        imgSrc={uninterestedChild}
        imgAlt="Slika prikazuje nezainteresirano dijete zbog manjka motivacije"
        imgTitle="Nezainteresirano dijete"
      />
      <Text as="p">
        U programu <BlogLink>Kokolingo</BlogLink> u tome, srećom, imate pomoć
        dodatnog animatora, a to je papigica Koko. Papigici Koko život su
        udahnuli grafički dizajneri i programeri, ali stručnim savjetima
        kreirali su je i logopedi. Zato se naša papigica uvijek trudi biti
        vedra, vesela i poticajna. Svako toliko pojavit će se na ekranu kako bi
        s pokojom kratkom rečenicom pohvalila dijete za njegov trud i potaknula
        ga da nastavi i ustraje s vježbama.
      </Text>
      <Text as="p">
        Bilo je zanimljivo, a ponekad i smiješno razgovarati s roditeljima koji
        su nam rekli kako je Koko puno bolji motivator od njih samih. Često bi
        komentirali kako bi teško poticali djecu na rad i motivirali ih za
        vježbe, a papigici Koko bi to uspjelo bez ikakva truda. Što kaže Koko –
        to je zakon! Šalu na stranu, roditelji našoj Koko nisu ništa zamjerili,
        ali svakako im je uvelike pomogla da vježbanje ne bude dosadno i
        suhoparno.
      </Text>
      <Text as="p">
        Dodatna motivacija u programu <BlogLink>Kokolingo</BlogLink> je i
        trgovina Koko. To je trgovina s kapama, šeširima, naočalama i još mnogim
        lijepim, smiješnim ili otkačenim detaljima kojima djeca mogu okititi
        svoju papigicu Koko. Jako je važno da valutu u trgovini predstavljaju
        novčići i dragulji koje su djeca sama zaradila, jer za svaku odrađenu
        vježbu dijete će zaraditi novčiće, a valute su različite na svakom otoku
        naše <BlogLink>Kokolingo</BlogLink> karte. Zato će se djeca veseliti što
        su svojim trudom uspjela obući Koko, ali će, primjerice, i paziti da se
        jako potrude na nekom otoku čijih im 20 novčića baš nedostaje za neku
        lijepu kapu.
      </Text>
      <BlogImage
        imgSrc={kokolingoShop}
        imgAlt="Prikaz trgovine gdje mozete obuci papigu Koko"
        imgTitle="Trgovina za papigu Koko"
      />
      <BlogImage
        imgSrc={kokolingoStories}
        imgAlt="Prikaz igre gdje se ponavlja procitano"
        imgTitle="Price"
      />
      <Text as="p">
        Ako želimo da dijete bude dovoljno uporno i motivirano u vježbanju,
        važno je da i mi kao roditelji preuzmemo dio odgovornosti, potrudimo se
        i osiguramo djetetu poticajnu sredinu za vježbanje. Kao roditelji,
        možemo poduzeti sljedeće korake:
        <ol>
          <li>
            <b>Važno je brinuti o djetetovim fiziološkim potrebama.</b> Ako je
            dijete umorno ili gladno, neće se moći dovoljno kvalitetno
            koncentrirati na vježbe i kratkoročno neće imati motivaciju za
            vježbanjem, a dugoročno će mumse činiti da je logopedsko vježbanje
            važnije od njega samoga, od njegovih primarnih potreba. S vremenom
            se time može postići da dijete stvori otpor prema vježbanju. Stoga,
            prije samog vježbanja provjerite s djetetom koliko je gladno, žedno,
            umorno…
          </li>
          <li>
            <b>Omogućite djetetu izbor.</b> Rijetko koji čovjek voli kada mu se
            nameće da nešto mora napraviti. Nuđenjem izbora djetetu mi mu ipak
            ne namećemo našu odluku, nego dajemo mogućnost slobode izbora, što
            mu daje osjećaj kontrole, dijete se uči preuzimati odgovornosti i
            produbljuje se odnos roditelj - dijete. Važno je da ponuđeni izbori
            uvijek budu nama prihvatljivi. Primjerice, djetetu možemo dati izbor
            u koje doba dana želi vježbati – nakon ručka ili prije tuširanja i
            spremanja za spavanje. Također, možemo djetetu dati i izbor kojim
            danima bi radije željelo vježbati. Važno je da izbori budu kreativni
            i dovoljno privlačni djetetu. U<BlogLink>Kokolingo</BlogLink>{' '}
            programu dijete može samo birati koje vježbe želi najprije vježbati
            i na taj je način omogućeno da dijete ima dojam kontrole te smo
            izbjegli dojam prisile.
          </li>
          <li>
            <b>
              Tijekom vježbanja stvorite ugodnu emotivnu klimu i povežite se s
              djetetom.
            </b>{' '}
            Važno je njegovati pozitivnu atmosferu tijekom vježbanja. Što manje
            kritizirati i ne posramljivati, optuživati, ucjenjivati, plašiti i
            sl. Stvaranjem neugodne atmosfere, dijete će prije odustati od
            vježbanja. Predstavite djetetu vrijeme za vježbanje kao nešto
            zabavno, zanimljivo i vrijeme gdje će kroz opušteniji i šaljiviji
            pristup puno toga naučiti. Zajedničko vrijeme koje provodite
            vježbajući može proći u vrlo ugodnom i zabavnom tonu ili u dosadnom
            i napornom. Vi kao roditelj imate utjecaj na to kakva će se klima
            stvoriti. Aplikacija <BlogLink>Kokolingo</BlogLink> osmišljena je i
            programirana upravo da bude zabavna, smiješna i opuštena, kako bi
            dijete u ugodnoj atmosferi što lakše usvajalo nove vještine.
          </li>
          <BlogImage
            imgSrc={connectingWithYourChild}
            imgAlt="Slika prikazuje mamu kako radi zajedno sa svojim djetetom"
            imgTitle="Povezivanje s djetetom"
          />
          <li>
            <b>Ističite djetetov napredak.</b> Važno je govoriti djetetu kada
            prepoznate da je napredovalo. Djeca se vole uspoređivati s
            vršnjacima, no kada bismo uspjeli u tome da se uspoređuju sa samima
            sobom, puno dobrobiti bismo napraviti za njihov psihički razvoj.
            Program <BlogLink>Kokolingo </BlogLink>
            omogućuje djeci razne načine praćenja svog napretka. Jedan od načina
            je taj da mogu vidjeti koliko su otoka do sada posjetili.
          </li>
          <li>
            <b>Nagradite djetetov trud.</b> Ističite i pohvaljujte dijete kada
            ustraje u vježbama ili kada ih napravi nekoliko za redom taj dan.
            Isticanjem truda u vježbanju može se poticati intrinzična motivacija
            koja je važna za dugoročno održavanje motivacije vježbanja. U
            programu <BlogLink>Kokolingo</BlogLink> djeci je omogućeno da svojim
            trudom skupljaju novčiće kako bi mogli kupiti dodatne sadržaje za
            svoju papigu Koko, što ih potiče da i dalje ustraju u vježbanju.
          </li>
          <li>
            <Text as="p">
              <b>Izbjegavajte kažnjavati dijete.</b> Korištenje kazne je vrlo
              popularno među roditeljima. Postoje brojni razlozi za to
              (primjerice, sprečavanja nepoželjnog ponašanja u budućnosti, može
              koristiti kako bi se izrazili neugodna emocionalna stanja poput
              ljutnje i frustracije i sl.). Roditelji često znaju kazniti dijete
              ako, primjerice, tog dana nije vježbalo ili ako generalno
              izbjegava vježbanje. Općenito govoreći, kazne nisu djelotvorne za
              motiviranje djece na vježbanje. Čak imaju i mnoge negativne
              posljedice, poput narušenog odnosa roditelj – dijete, neugodne
              djetetove emocionalnosti (plakanje, vrištanje..) i sl.
            </Text>
            <Text as="p">
              Umjesto kazni, potičite dijete da preuzima odgovornost za svoje
              izbore i ponašanje. Najavite mu posljedice njegovih izbora i
              dosljedno ih izvršite. Primjerice, možete mu reći: „Ako danas
              vježbamo, biraš da prije spavanja gledaš tri crtana filma, a ako
              danas ne vježbamo, biraš jedan crtani film prije spavanja.“ Na
              takav način djeca uče preuzimati odgovornost za svoje ponašanje i
              čuva se pozitivan odnos dijete – roditelj, jer sami mogu izabrati
              što žele.
            </Text>
          </li>
          <li>
            <b>Dopustite djetetu da prilikom vježbanja griješi.</b> Kako bi se
            dijete ugodno osjećalo, bitno je da cijenimo pogreške i pokazujemo
            djetetu kako ćemo kroz pogreške i trud s vremenom doći do boljih
            rezultata. Važno je da ne posramljujemo dijete zbog grešaka ili da
            se zato ne ljutimo na njega. Djetetu je potrebno kontinuirano davati
            dopuštenje da smije i da je u redu griješiti. Na taj način potičemo
            da dijete ostane motivirano, osjeća se ugodno i uživa u vježbama.
            Djeca će griješiti i to je sasvim normalno i uobičajeno. U programu{' '}
            <BlogLink>Kokolingo</BlogLink> možete vidjeti kako se ne ističu
            pogrešni odgovori, nego samo točni, sve s ciljem poticanja i
            održavanja dječje motivacije.
          </li>
        </ol>
      </Text>
      <BlogImage
        imgSrc={familyStudy}
        imgAlt="Slika prikazuje cijelu obitelj kako zajedno uce"
        imgTitle="Cijela obitelj uci"
      />
      <Text as="p">
        Potičite logopedsko vježbanje kroz igru, jer je tada atmosfera najčešće
        ugodna, opuštena, zabavna i pogodna za stjecanje i održavanje
        kratkoročne i dugoročne motivacije. Predstavite djetetu program{' '}
        <BlogLink>Kokolingo</BlogLink> kao nešto zabavno, zanimljivo i korisno.
        Budite ugodni, nježni, zabavni, ne kritizirajte, dopustite djetetu da
        pogriješi te ga ne posramljujte. Vidjet ćete kako će napredovati i to će
        mu biti dodatni veliki motivator – razvoj novih vještina.
      </Text>
      <Text as="p">
        Bitno je prisjetiti se da djeca posjeduju prirodnu motivaciju za
        učenjem, rastom i razvojem na zdrav i zreo način. A to mogu (lakše)
        ostvariti u okruženju koje je strpljivo, poticajno, ugodno, podržavajuće
        i puno ljubavi.
      </Text>
      <Text as="p">
        Dario Antić, magistar psihologije, specijalizant transakcijske analize,
        praktičar terapije igrom
      </Text>
      <BlogLiterature>
        <ul>
          <li>
            <i>
              Reeve, J. (2010). Razumijevanje motivacije i emocija.
              Jastrebarsko: Naklada slap.
            </i>
          </li>
          <li>
            <i>
              Oatley, K. i Jenkins, J., M. (2003). Razumijevanje emocija.
              Jastrebarsko: Naklada slap.
            </i>
          </li>
          <li>Predstavljanje aplikacije Kokolingo: E-glas</li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default SpeechTherapistsAndKokolingo;
